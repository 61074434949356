<template>
  <div class="product-carousel slider-carousel" :style="styleForSize">
    <div
        v-if="!isInitiatedGlide"
        class="product-carousel__scrollable">
      <div class="product-carousel__scrollable-line">
        <SfOProductCard
          v-for="product in carouselProducts"
          :key="product.id"
          :image="product.image || product.src"
          :title="product.title"
          badge-label="-50%"
          :regular-price="product.price.regular"
          :special-price="product.price.special"
          :link="convertProductLink(product.link)"
          :product="product"
          wish-list-icon="heart"
          link-tag="router-link"
          :is-new-post="isNewPost"
          @linkClicked="linkClicked"
          :product-quantity="product.quantityText"
          :show-qty-permanently="showQtyPermanently"
          :is-replacement="isReplacement"
          :lazy="lazy"
          @replaceProduct="replaceProduct"
        />
      </div>
    </div>
    <SfOCarousel
      v-else
      class="desktop-only"
      :class="{ 'product-carousel--scrollable': scrollable }"
      :settings="sliderSettings"
      :key="carouselUpdateKey"
    >
      <template #prev="{go}">
        <SfButton
          v-show="sliderPerPage > 1"
          class="sf-arrow sf-arrow--rounded sf-arrow-prev"
          :class="prevButtonClass"
          aria-label="previous"
          @click="goPrev(); go('prev')"
          :disabled="sliderData.disablePrev"
        />
      </template>
      <SfCarouselItem
        v-for="(product, index) in carouselProducts"
        :key="product.id"
      >
        <SfOProductCard
          :image="product.image || product.src"
          :title="product.title"
          badge-label="-50%"
          :regular-price="product.price.regular"
          :special-price="product.price.special"
          :link="convertProductLink(product.link)"
          :product="product"
          wish-list-icon="heart"
          link-tag="router-link"
          :alt-name="makeAlt(product, index)"
          :is-new-post="isNewPost"
          @linkClicked="linkClicked"
          :product-quantity="product.quantityText"
          :show-qty-permanently="showQtyPermanently"
          :is-replacement="isReplacement"
          :lazy="lazy"
          @replaceProduct="replaceProduct"
        />
      </SfCarouselItem>
      <template #next="{go}">
        <SfButton
          v-show="sliderPerPage > 1"
          class="sf-arrow sf-arrow--rounded sf-arrow-next"
          :class="nextButtonClass"
          aria-label="next"
          @click="goNext(); go('next')"
          :disabled="sliderData.disableNext"
        />
      </template>
    </SfOCarousel>
  </div>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';
import SfOCarousel from 'theme/components/storefront-override/SfOCarousel';
import { prepareCategoryProduct } from 'theme/helpers';
import SfOProductCard from 'theme/components/storefront-override/SfOProductCard';
import { BREAKPOINT_SM, BREAKPOINT_MD, BREAKPOINT_LG, BREAKPOINT_XSM, BREAKPOINT_XL } from 'theme/helpers/breakpoints';
import DeviceType from 'theme/mixins/DeviceType';
import GoogleTagManager from 'theme/mixins/gtm';
import isPlainObject from 'lodash/isPlainObject';
import { mapGetters } from 'vuex';

export default {
  name: 'MProductCarousel',
  components: {
    SfOCarousel,
    SfOProductCard,
    SfButton
  },
  mixins: [
    DeviceType,
    GoogleTagManager
  ],
  props: {
    altCounterOffset: {
      type: Number,
      default: 0
    },
    altName: {
      type: String,
      default: ''
    },
    products: {
      type: Array,
      default: () => []
    },
    esUrlParam: {
      type: String,
      default: ''
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    sliderSettings: {
      type: Object,
      default: () => {
        return {
          type: 'slider',
          animationDuration: 400,
          animationTimingFunc: 'ease-in-out',
          perView: 6,
          gap: 10,
          dragThreshold: false,
          breakpoints: {
            [BREAKPOINT_LG - 1]: {
              perView: 5
            },
            [BREAKPOINT_MD - 1]: {
              perView: 4
            },
            [BREAKPOINT_SM - 1]: {
              perView: 2.3,
              swipeThreshold: false,
              dragThreshold: false
            }
          }
        };
      }
    },
    showQtyPermanently: {
      type: Boolean,
      default: false
    },
    arrowColor: {
      type: String,
      default: 'gray'
    },
    isReplacement: {
      type: Boolean,
      default: false
    },
    lastEmpty: {
      type: Boolean,
      default: false
    },
    needGtm: {
      type: Boolean,
      default: true
    },
    needGtmAds: {
      type: Boolean,
      default: false
    },
    lazy: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isInitiatedGlide: false,
      productStock: {
        type: Object,
        default: () => ({})
      },
      qty: 1,
      qtyValidationError: '',
      sliderData: {
        currentPage: 1,
        disablePrev: true,
        disableNext: false
      },
      carouselUpdateKey: 0
    };
  },
  computed: {
    ...mapGetters({
      isNewPost: 'shipping-module/isCurrentNewPost'
    }),
    styleForSize () {
      const breakpointXSM = this.sliderSettings?.breakpoints?.[BREAKPOINT_XSM - 1]?.perView || 2;
      const breakpointSM = this.sliderSettings?.breakpoints?.[BREAKPOINT_SM - 1]?.perView || 3
      const breakpointMD = this.sliderSettings?.breakpoints?.[BREAKPOINT_MD - 1]?.perView || breakpointLG
      const breakpointLG = this.sliderSettings?.breakpoints?.[BREAKPOINT_LG - 1]?.perView || breakpointXL
      const breakpointXL = this.sliderSettings?.breakpoints?.[BREAKPOINT_XL - 1]?.perView || this.sliderSettings?.perView || 4

      return [
        `--xsm-break-val: ${breakpointXSM}`,
        `--sm-break-val: ${breakpointSM}`,
        `--md-break-val: ${breakpointMD}`,
        `--lg-break-val: ${breakpointLG}`,
        `--xl-break-val: ${breakpointXL}`
      ].join(';')
    },
    carouselProducts () {
      return this.products.map(prepareCategoryProduct).slice(0, 12);
    },
    sliderPerPage () {
      return Math.ceil(this.carouselProducts.length / this.sliderSettings.perView)
    },
    prevButtonClass () {
      return [`sf-arrow--${this.arrowColor}`, { 'sf-arrow--active': !this.sliderData.disablePrev }]
    },
    nextButtonClass () {
      return [`sf-arrow--${this.arrowColor}`, { 'sf-arrow--active': !this.sliderData.disableNext }]
    },
    productsSku () {
      return this.products.map(e => e.sku)
    }
  },
  watch: {
    isDesktop: {
      immediate: true,
      handler: function (val, oldVal) {
        if (val === oldVal) return

        this.$nextTick(() => {
          this.isInitiatedGlide = val
        })
      }
    },
    productsSku (newValue, oldValue) {
      const valueChanged = JSON.stringify(newValue) !== JSON.stringify(oldValue)
      if (!newValue || !valueChanged) return
      if (this.carouselUpdateKey > 10) this.carouselUpdateKey = 0
      this.carouselUpdateKey++
    }
  },
  methods: {
    goNext () {
      this.sliderData.currentPage = (this.sliderData.currentPage + 1)
      this.sliderData.disableNext = (this.sliderPerPage === this.sliderData.currentPage)
      this.sliderData.disablePrev = false
    },
    goPrev () {
      this.sliderData.currentPage = (this.sliderData.currentPage - 1)
      this.sliderData.disablePrev = (this.sliderData.currentPage === 1)
      this.sliderData.disableNext = false
    },
    convertProductLink (url) {
      const parentSku = url.params?.parentSku;
      const slug = url.params?.slug;

      return isPlainObject(url) ? `p/${parentSku}/${slug}${this.esUrlParam}` : `${url}${this.esUrlParam}`
    },
    linkClicked () {
      this.$emit('linkClicked');
    },
    replaceProduct (product) {
      this.$emit('replaceProduct', product);
    },
    makeAlt (product, index) {
      return `Фото ${this.altCounterOffset + index + 1} - ${this.altName || product.title}`
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$bus.$emit('seo/check-duplicate-attr-alt')
    })

    if (this.sliderPerPage === 1) {
      this.sliderData.disableNext = true
      this.sliderData.disablePrev = true
    }

    if (this.needGtm) {
      this.gtmProductsHandler(this.carouselProducts, 'view_item_list')
    }

    if (this.needGtmAds) {
      this.gtmProductsHandlerAds(this.carouselProducts, 'view_item_list_ads')
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'theme/css/breakpoints';

.product-carousel {
  &__wrapper {
    overflow: hidden;
  }
  &__scrollable {
    --product-scrollable-gap: var(--spacer-10);
    //overflow: auto hidden;
    overflow: auto hidden;
    display: flex;

    &::-webkit-scrollbar {
      display: none;
    }

    @-moz-document url-prefix() {
      scrollbar-width: none;
    }

    &-line {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      gap: var(--spacer-10);
      padding-left: var(--spacer-10);
      padding-right: var(--spacer-10);
      box-sizing: content-box;
    }
  }

  ::v-deep {
    .glide__slides {
      min-width: 100%;
    }

    .glide__slides > *, .product-carousel__scrollable-line > * {
      width: 100%;
      max-width: calc(40% - 10px);

      @media (min-width: 489px){
        max-width: calc(40% - 10px);
      }

      @media (min-width: $tablet-min){
        max-width: calc(100%/var(--md-break-val, 4) - 10px);
      }
    }

    .product-carousel__scrollable-line > * {
      min-width: calc(40% - 10px);

        @media (min-width: 489px){
          min-width: calc(40% - 10px);
        }

        @media (min-width: $tablet-min){
          min-width: calc(100%/var(--md-break-val, 4) - 10px);
        }

        @media (min-width: $desktop-min){
          min-width: calc(100%/var(--lg-break-val, 4) - 10px);
          max-width: calc(100%/var(--lg-break-val, 4) - 10px);
        }

        @media (min-width: $desktop-l-min){
          min-width: calc(100%/var(--xl-break-val, 4) - 10px);
          max-width: calc(100%/var(--xl-break-val, 4) - 10px);
        }

        &:last-child {
          margin-right: var(--spacer-10);
        }
    }
  }

  &--scrollable {
    ::v-deep {
      .sf-carousel__controls {
        right: var(--spacer-10);
        top: -2rem;
        display: flex;
        position: absolute;
        width: 94px;
        left: initial;
        gap: 10px;

        .sf-button {
          margin: 0;
        }
      }

      @include for-mobile {
        .sf-carousel__controls {
          display: none;
        }
      }

      .glide__track {
        padding-top: var(--spacer-12);
      }

      .sf-product-card {
        overflow: hidden;

        &:before {
          display: none;
        }

        &:hover {
          background: transparent;
        }
      }
    }
  }

  ::v-deep {
    .sf-carousel__wrapper {
      @media (min-width: $tablet-min) {
        padding-inline: var(--spacer-10);
      }
    }

    .sf-product-card__wrapper {
      height: 100%;
    }

    .sf-carousel-item {
      height: auto;

      .sf-product-card {
        height: 100%;
      }
      .sf-price__special {
        @include for-mobile {
          margin-bottom: 2px;
        }
      }
    }

    .glide__track,
    .glide__slide {
      @include for-mobile {
        overflow: auto hidden;

        &::-webkit-scrollbar {
          display: none;
        }

        @-moz-document url-prefix() {
          scrollbar-width: none;
        }
      }
    }

    .glide__slides {
      @include for-mobile {
        overflow: hidden;

        &::-webkit-scrollbar {
          display: none;
        }

        @-moz-document url-prefix() {
          scrollbar-width: none;
        }
      }
    }

    .glide__slides {
      @include only-mobile {
        margin-left: var(--spacer-10);
      }
    }

    /* Skeleton part */
    .glide:not(.glide--slider) .glide__slides {
      @include only-mobile {
        padding-right: var(--spacer-10);
        width: 135%; // to show 2.3 items
      }
    }

    .glide:not(.glide--slider) .glide__slide {
      &:first-child {
        margin-right: var(--spacer-5);
      }

      @include only-mobile {
        &:nth-child(2) {
          margin-inline: var(--spacer-5);
        }

        &:nth-child(3) {
          margin-left: var(--spacer-5);
        }

        &:not(:nth-child(-n+3)) {
          display: none;
        }
      }

      @include for-tablet {
        &:nth-child(2), &:nth-child(3) {
          margin-inline: var(--spacer-5);
        }

        &:nth-child(4) {
          margin-left: var(--spacer-5);
        }

        &:not(:nth-child(-n+4)) {
          display: none;
        }
      }

      @media (min-width: $desktop-min) and (max-width: $desktop-l-min) {
        &:nth-child(2), &:nth-child(3), &:nth-child(4) {
          margin-inline: var(--spacer-5);
        }

        &:nth-child(5) {
          margin-left: var(--spacer-5);
        }

        &:not(:nth-child(-n+5)) {
          display: none;
        }
      }

      @media (min-width: $desktop-l-min) {
        &:nth-child(n+2):nth-child(-n+5) { // nth from 2 to 5
          margin-inline: var(--spacer-5);
        }

        &:nth-child(6) {
          margin-left: var(--spacer-5);
        }

        &:not(:nth-child(-n+6)) {
          display: none;
        }
      }
    }
  }
}
</style>
